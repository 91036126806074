import { getAppDefinitions, MembersAreaAppId } from '@wix/members-area-app-definitions';
import { IntegrationApplication, PageWidget, IntegrationApplicationMap } from '@wix/members-area-integration-kit';

import enforceSequentiality from '../enforceSequentiality';
import { toMonitored, log } from '../../utils/monitoring';
import * as state from './applicationState';
import { isApplicationReady } from '../applicationState';
import { addApplications } from '../platform-api/addApplications';
import { removeMembersAreaPage } from '../platform-api/removeMembersAreaPage';
import { asyncFilter } from '../../utils/promises';
import { createBIService } from '../../utils/bi';
import { getApplicationPage, getPageByIntegrationApp } from './pages';
import { isMyWishlist } from './myWishlistIntegration';
import { removeLoosePagesOutsideMembersArea } from '../data-fixers/pages';
import { putWidgetOnPage } from '../services/widgets';
import { isAdditionalPageWidgetsEnabled } from '../../utils/experiments';
import { removeComponentsByAppDefIds } from '../wrappers/components';
import { arePagesEqual, getUniquePages } from '../../utils/pages';

const isAddApplication = (app: IntegrationApplication) => app.method === 'addApplication';
const hasNoDependencies = (allApps: IntegrationApplication[]) => (app: IntegrationApplication) =>
  !allApps.some((app2) => app2.pageId === app.pageId);
const isAppInstalled = (editorSDK) => (appDefinitionId) =>
  editorSDK.document.tpa.isApplicationInstalled('', { appDefinitionId });

const maybeAddApplications = async (applications: IntegrationApplication[], editorSDK) => {
  const isReady = await isApplicationReady(editorSDK);
  if (!isReady) {
    console.warn('Members Area installation was corrupted so the integrations pages will not be added');
    log('Skipping addApplications as the application is not ready and probably already deleted');
    return;
  }
  const forceHorizontalLayout = false;
  return toMonitored('editorApi.addApplications', () =>
    addApplications({ editorSDK, applications, forceHorizontalLayout }),
  );
};

const removePage = ({ id, editorSDK }) =>
  toMonitored('editorApi.removeMembersAreaPage', () => removeMembersAreaPage({ editorSDK, id }));

export const registerMembersAreaApps = (applications: IntegrationApplication[], verticalAppDefId: string, editorSDK) =>
  toMonitored('editorApi.registerMembersAreaApps', async () => {
    const applicationDefinitions = await getAppDefinitions({ applications, editorSDK });
    const currentIntegratedAppsMap = state.getAllIntegratedApps();
    const verticalsApps = getUniquePages([
      ...(currentIntegratedAppsMap[verticalAppDefId] ?? []),
      ...applicationDefinitions,
    ]);
    // @ts-ignore - can be removed after Members Area Integration Kit update
    state.setIntegratedApps({
      ...currentIntegratedAppsMap,
      [verticalAppDefId]: verticalsApps,
    });

    enforceSequentiality(() => removeLoosePagesOutsideMembersArea(editorSDK, applications));
  });

export const installRegisteredApps = async (verticalAppDefId: string, editorSDK) => {
  const biService = await createBIService({ editorSDK });
  biService.verticalTriggeredMaInstallInitiated({ originAppId: verticalAppDefId });

  return enforceSequentiality(() =>
    toMonitored('editorApi.installRegisteredApps', async () => {
      const integrationApps: IntegrationApplication[] = state.getVerticalsApps(verticalAppDefId);
      const integrationAppsToInstall = integrationApps.filter((app) => app.shouldInstallInitially !== false);

      if (integrationAppsToInstall.length > 0) {
        await maybeAddApplications(integrationAppsToInstall, editorSDK);
      }

      biService.verticalTriggeredMaInstallSuccess({ originAppId: verticalAppDefId });
    }),
  );
};

export const handleVerticalDeletion = (verticalAppDefId: string, editorSDK) =>
  enforceSequentiality(() =>
    toMonitored('editorApi.handleVerticalDeletion', async () => {
      const verticalsApps: IntegrationApplication[] = state.getVerticalsApps(verticalAppDefId);
      const allIntegratedAppsMap: IntegrationApplicationMap = state.getAllIntegratedApps();
      const installedVerticalIds: string[] = await asyncFilter(
        Object.keys(allIntegratedAppsMap),
        isAppInstalled(editorSDK),
      );
      const appsOfOtherVerticals = installedVerticalIds.reduce<IntegrationApplication[]>(
        (acc, appDefId) => acc.concat(allIntegratedAppsMap[appDefId] ?? []),
        [],
      );
      const integratedAppsToDelete = verticalsApps
        .filter(hasNoDependencies(appsOfOtherVerticals))
        .filter(isAddApplication);

      for (const app of integratedAppsToDelete) {
        const pageToRemove = await getPageByIntegrationApp({ editorSDK, app });
        if (pageToRemove) {
          await removePage({ editorSDK, id: pageToRemove.id });
        }
        if (await isAdditionalPageWidgetsEnabled()) {
          await removeVerticalWidgets(editorSDK, app.appDefinitionId);
        }
      }
    }),
  );

export const getRegisteredApps = (editorSDK) =>
  enforceSequentiality(() =>
    toMonitored('editorApi.getRegisteredApps', async () => {
      const allIntegratedAppsMap: IntegrationApplicationMap = state.getAllIntegratedApps();
      const registeredVerticalIds = Object.keys(allIntegratedAppsMap);
      const installedVerticalIds: string[] = await asyncFilter(registeredVerticalIds, isAppInstalled(editorSDK));
      const filteredAppsMap: IntegrationApplicationMap = await installedVerticalIds.reduce(async (acc, appDefId) => {
        const notInstalledPages = await asyncFilter(
          allIntegratedAppsMap[appDefId],
          async (app) => !(await getPageByIntegrationApp({ editorSDK, app })) && !isMyWishlist(app),
        );
        return { ...(await acc), [appDefId]: notInstalledPages };
      }, Promise.resolve({}));

      return filteredAppsMap;
    }),
  );

const areWidgetsEqual = (widget1: PageWidget, widget2: PageWidget) => {
  return (
    widget1.targetPage.appDefinitionId === widget2.targetPage.appDefinitionId &&
    widget1.targetPage.pageId === widget2.targetPage.pageId &&
    widget1.appDefinitionId === widget2.appDefinitionId &&
    widget1.widgetId === widget2.widgetId
  );
};

export const registerAdditionalWidgets = async (widgetList: PageWidget[]) => {
  if (!(await isAdditionalPageWidgetsEnabled()) || !widgetList) {
    return;
  }
  return toMonitored('editorApi.registerAdditionalWidgets', async () => {
    const existingWidgets = state.getAdditionalWidgets();

    let newWidgets = widgetList;
    existingWidgets.forEach((widget) => {
      newWidgets = newWidgets.filter((newWidget) => !areWidgetsEqual(widget, newWidget));
    });
    state.setAdditionalWidgets(existingWidgets.concat(newWidgets));
  });
};

export const getRegisteredWidgets = async (): Promise<PageWidget[]> => {
  if (!(await isAdditionalPageWidgetsEnabled())) {
    return [];
  }
  return state.getAdditionalWidgets();
};

export const removeVerticalWidgets = async (editorSDK, verticalId: string) => {
  if (!(await isAdditionalPageWidgetsEnabled())) {
    return;
  }
  const pageWidgets = state.getAdditionalWidgets();
  const filteredWidgets = pageWidgets.filter((widget) => widget.appDefinitionId !== verticalId);
  state.setAdditionalWidgets(filteredWidgets);
  return removeComponentsByAppDefIds(editorSDK, [verticalId]);
};

const getUniqueTargetPages = (widgetList: PageWidget[]) => {
  const targetPages = widgetList.map((widget) => widget.targetPage);
  const uniqueTargetPages = getUniquePages(targetPages);
  return uniqueTargetPages;
};

const widgetOrderComparator = (widget1: PageWidget, widget2: PageWidget): number => {
  if (widget1?.order === widget2?.order) {
    return 0;
  }
  if (!widget1.order && widget1.order !== 0) {
    return 1;
  }
  if (!widget2.order && widget2.order !== 0) {
    return -1;
  }
  return widget1.order < widget2.order ? -1 : 1;
};

export const installAdditionalWidgets = async (editorSDK) => {
  if (!(await isAdditionalPageWidgetsEnabled())) {
    return;
  }
  return toMonitored('editorApi.installAdditionalWidgets', async () => {
    const widgetList = await getRegisteredWidgets();
    const uniquePages = getUniqueTargetPages(widgetList);
    for (const targetPage of uniquePages) {
      const page = await getApplicationPage(editorSDK, targetPage.appDefinitionId, targetPage.pageId);
      if (page && page.id) {
        const pageWidgets = widgetList
          .filter((widget) => arePagesEqual(widget.targetPage, targetPage))
          .sort(widgetOrderComparator);
        for (const widget of pageWidgets) {
          await putWidgetOnPage(editorSDK, widget, page.id);
        }
      }
    }
  });
};
