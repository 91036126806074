import Experiments from '@wix/wix-experiments';

let conductedExperiments;

async function maybeConductExperiments() {
  if (!conductedExperiments) {
    conductedExperiments = new Experiments({ scope: 'members-area' });
  }
  await conductedExperiments.ready();
}

async function areAppWidgetsEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AppWidgets') === 'true';
}

async function isOB19052FixEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.OB19052Fix') === 'true';
}

async function isManageMemberPagesEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.ManageMemberPages') === 'true';
}

async function isPlatformPagesRemoveEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.PlatformPageRemove') === 'true';
}

async function isAdditionalPageWidgetsEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AdditionalPageWidgets') === 'true';
}

async function isADIHorizontalLayoutEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.ADIHorizontalLayout') === 'true';
}

async function isControllersDeletionForEditorXEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.EditorXControllersDeletion') === 'true';
}

export {
  areAppWidgetsEnabled,
  isOB19052FixEnabled,
  isManageMemberPagesEnabled,
  isPlatformPagesRemoveEnabled,
  isAdditionalPageWidgetsEnabled,
  maybeConductExperiments,
  isADIHorizontalLayoutEnabled,
  isControllersDeletionForEditorXEnabled,
};
